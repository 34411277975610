import { mapActions, mapGetters } from "vuex";
import * as types from "../../../store/types";
import userInfoService from "../../../services/userInfoService";
export default {
    name: "accounting",
    components: {},
    props: [],
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            userInfo: types.GET_USER_INFO,
        }),
    },
    mounted() {
        this.getuserinfo();
    },
    methods: {
        ...mapActions({
            userInfoAction: types.ACTION_USER_INFO,
        }),
        logout() {
            this.$router.push(`/`);
        },
        getuserinfo(){
            userInfoService.getUserInfo()
            .then((response) => {
                this.userInfoAction(response.data)})
        },
        
    },
};
